
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component } from 'vue-property-decorator';

  @Component({
    components: {
      BaseToolbar
    }
  })
export default class ChangeHistoryToolbar extends BaseToolbar {
  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get searchText() {
    return this.localComponent.searchText;
  }

  set searchText(value: string) {
    this.localComponent.searchText = value;
  }
}
